/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';


@import '../node_modules/ngx-spinner/animations/ball-atom.css';
@import '../node_modules/flag-icons/css/flag-icons.min.css';
@import '../node_modules/ngx-ui-switch/ui-switch.component.css';

@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs5-alert';

/* Core Grid CSS */
@import 'ag-grid-enterprise/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-enterprise/styles/ag-theme-quartz.css';

html, body { height: 100%; }
body
{
   margin: 0;
   padding: 0;
   font-family: "Roboto", Arial, Helvetica, sans-serif;
   background-color: #f8f8f8;

}

.containingdiv {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas:
    "navbar"
    "content"
    "footer";
  height: 100vh;
}

mat-drawer-container {
  grid-area: content;
  grid-column: 1 / -1;
  margin-bottom: 1px;
}

mat-drawer-content {
  height: 100%;
  overflow:visible;
}

lib-primary-navbar {
  grid-area: navbar;
  grid-column: 1 / -1;
}

lib-footer {
  grid-area: footer;
  grid-column: 1 / -1;
}

app-home, app-dashboard, app-product-duty-classification, app-vendor-management, app-client-management,
app-item-type-management, app-tenant-selection {
  grid-area: content;
  grid-column: 1 / -1;
}
